import { extractCommonMutations } from "../../shared"

export const extractMutations = () => ({
  ...extractCommonMutations(),

  RESET_FILTERS: state => (state.filters = defaultFiltersObject())
})

export const defaultFiltersObject = () => ({
  carTypes: [],
  searchValue: ""
})
