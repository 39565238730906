import { api, i18n } from "@/config"
import { showSuccessToast } from "@/helpers/toasts"
import { handleFailedResponse } from "@/helpers/common"

const headers = { "Content-Type": "multipart/form-data" }

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, id) => {
    try {
      const { data } = await api.get(`${baseURI}/${id}`)
      commit("SET_ITEM", data.data)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  CREATE_ITEM: async ({ commit }, formData) => {
    try {
      const { data } = await api.post(baseURI, formData, { headers })
      commit("SET_ITEM", data.data)
      showSuccessToast({ text: i18n.t("company_system.car_class_settings.car_class_created") })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  },

  UPDATE_ITEM: async ({ commit }, payload) => {
    try {
      const { data } = await api.put(`${baseURI}/${payload.id}`, payload.formData, { headers })
      commit("SET_ITEM", data.data)
      showSuccessToast({ text: i18n.t("company_system.car_class_settings.car_class_updated") })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
