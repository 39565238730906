// mixins
import withPermissions from "@/mixins/withPermissions"
import withConfirmation from "@/mixins/withConfirmation"

export default {
  mixins: [withConfirmation, withPermissions],

  beforeRouteLeave(to, _from, next) {
    this.beforeRouteLeaveHandler({ to, next, isChanges: this.useConfirm, exitHandler: () => this.setDirty(false) })
  },

  computed: {
    useConfirm() {
      return this.hasEditPermission() && this.isDirty
    }
  }
}
